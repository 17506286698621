import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";


const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const Category = lazy(()=> import('./pages/Category'))
const SubCategory = lazy(()=> import('./pages/SubCategory'))
const Tag = lazy(()=> import('./pages/Tag'))
const Dashboard = lazy(()=>import('./pages/Dashboard'))
const Fabric = lazy(()=> import('./pages/Fabric'))
const Colour = lazy(()=> import('./pages/Colour'))
const Season = lazy(()=> import('./pages/Season'))
const UsersPlan = lazy(()=> import('./pages/UsersPlan'))
const AdminUsers = lazy(()=> import('./pages/AdminUsers'))
const Role = lazy(()=> import('./pages/Role'))
const Invoice = lazy(()=> import('./pages/Invoice'))
const Project = lazy(() =>import('./pages/Project'))
const Pricing = lazy(() =>import('./pages/Pricing'))

export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard-old" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/category" component={Category} />
        <Route path="/sub-category" component={SubCategory} />
        <Route path="/tag" component={Tag} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/fabric" component={Fabric} />
        <Route path="/colour" component={Colour} />
        <Route path="/season" component={Season} />
        <Route path="/usersPlan" component={UsersPlan} />
        <Route path="/adminUsers" component={AdminUsers} />
        <Route path="/role" component={Role} />
        <Route path="/invoice" component={Invoice} />
        <Route path="/project" component={Project} />
        <Route path="/pricing" component={Pricing} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
