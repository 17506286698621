/**
 * ASSETS BASE PATH
 */
// export const ASSETS_PATH = "http://localhost:3001";
// export const ASSETS_PATH = "https://service.wookai.com";

/**
 * BASE PATH for APIS
 */
 export const BASE_URL = "https://devapi.ambaram.ai/api/v1";
  //  export const BASE_URL = "http://20.124.126.130:3000/api/v1";
  //  export const BASE_URL = "https://test-service.ambaram.ai/api/v1";


/**
 * Auth
 */
export const AUTH_ENDPOINT = `${BASE_URL}/auth`;

/**
 * NonAuth APIS
 */
export const CATEGORY_ENDPOINT = `${BASE_URL}/category`;

/**
 * SubCategory
 */
export const SUB_CATEGORY = `${BASE_URL}/subCategory`;

/**
 * Tag
 */
export const TAG = `${BASE_URL}/tag`;

/**
 * fabric
 */
export const FABRIC = `${BASE_URL}/fabric`;

   
 /**
  * season
  */
  export const SEASON = `${BASE_URL}/season` 


/**
 * Role
 */
 export const ROLE_ENDPOINT =  `${BASE_URL}/role`;

/**
 * Role
 */
 export const COLOUR_ENDPOINT =  `${BASE_URL}/colour`;

 //shareWithEmail
export const ACTIVE_PRODUCT = `${BASE_URL}/dashboard/activeProducts`;

//totalUploads
export const TOTAL_UPLOADS = `${BASE_URL}/dashboard/totalUploads`;

//GET PLANS 
export const GET_PLANS = `${BASE_URL}/payment/getPlans`;

/**
 * Role
 */
export const  USERS_FOR_ADMIN =  `${BASE_URL}/users/getUserListForAdmin`;

//Team
export const TEAM = `${BASE_URL}/team`;

//User
export const USER_STATUS = `${AUTH_ENDPOINT}/updateUserStatus`;

//userArchive
export const USER_ARCHIVE  = `${AUTH_ENDPOINT}/archiveUser`;

//get user deatil
export const USER_DETAIL = `${BASE_URL}/users/detail`;

//get all users
export const USER = `${BASE_URL}/users`;

//get all users
export const ADMIN_USERS = `${USER}/adminUserList`;

//get all users
export const UPDATE_USER_BY_ADMIN = `${BASE_URL}/team/updateByAdmin`;

/*
 * Pricing
 */
export const PRICING = `${BASE_URL}/price/6448d5f436689734389c4742`;

